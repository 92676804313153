import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Header from '@sections/Header';
import About from '@sections/About';
import Credits from '@sections/Credits';
import Sponsors from '@sections/Sponsors';
import Exhibition from '@sections/Exhibition';
import Events from '@sections/Events'
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <About />
    <Exhibition />
    <Sponsors />
    <Events />
    <Credits />    
    <Footer />
  </Layout>
);

export default IndexPage;
