import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import ExternalLink from '@common/ExternalLink';
import Calendar from '@common/Calendar';
import { Section, Container } from '@components/global';

const EVENTS = [
  {
    title: 'City of Edinburgh Council - Housing Sustainability Approach', start: new Date('November 2, 2021 18:00:00'), end: new Date('November 2, 2021 19:00:00'), organiser: 'Edinburgh City Council',
    description: 'The Proposed City Plan 2030 sets out policies and proposals to guide development, <br/> this event will share how it does this with sustainability at its core.',
    link: 'https://www.eventbrite.co.uk/e/199198858147'
  },
  {
    title: 'Low Carbon Architecture', start: new Date('November 4, 2021 18:00:00'), end: new Date('November 4, 2021 20:00:00'), organiser: 'SpACE',
    description: 'Three prominent Edinburgh architects <br/> – Reiach & Hall, Michael Laird and Bennetts Associates - <br/> describe their approach to low-carbon architecture, <br/> with examples of re-use and newbuild, supported by data and good engineering.',
    link: 'https://www.eventbrite.co.uk/e/199155348007'
  },
  {
    title: 'RICS Circular Economy', start: new Date('November 5, 2021 18:00:00'), end: new Date('November 5, 2021 20:00:00'), organiser: 'RICS',
    description: 'Construction causes 11% of global emissions – what can we do to reduce this? <br/> This evening event will feature presentations from: <br/> Zero Waste Scotland, Concular GLM, EAA, EGG Lighting.',
    link: 'https://www.eventbrite.co.uk/e/196494118197'
  },
  {
    title: 'UKGBC Scotland', start: new Date('November 8, 2021 18:00:00'), end: new Date('November 8, 2021 21:00:00'), organiser: 'UKGBC',
    description: 'UK Green Builidng Council Scotland launch',
    link: 'https://www.ukgbc.org/events/cop26-ukgbc-scotland-launch-2/'
  },
  {
    title: 'Cop26 in the Edinburgh Community', start: new Date('November 9, 2021 09:30:00'), end: new Date('November 9, 2021 10:45:00'), organiser: 'Edinburgh Chamber of Commerce',
    description: 'This panel event will focus on showcasing the great work being done across the city by local community groups and third sector organisations, <br/> who are working together to drive the sustainability and net-zero agenda for the city.',
    link: 'https://www.eventbrite.co.uk/e/edinburghs-sustainable-community-projects-are-a-force-for-good-tickets-193560583917?aff=ebdsoporgprofile'
  },
  {
    title: 'Transforming commercial property: The route to net zero', start: new Date('November 9, 2021 18:00:00'), end: new Date('November 9, 2021 20:00:00'), organiser: 'RICS',
    description: 'A panel event, focusing on the role of commercial property in transitioning to net zero. <br/> Jones Lang Lasalle, Lendlease and TFT Consultants will focus on driving net zero in the operation of buildings, <br/> the impact of sustainability initiatives on market value, the role of green dilapidations and future policies and regulations.',
    link: 'https://www.eventbrite.co.uk/e/196548571067 '
  },
  {
    title: 'HES Retrofit at SpACE Public interaction Day', start: new Date('November 11, 2021 10:30:00'), end: new Date('November 11, 2021 17:30:00'), organiser: 'Historic Environment Scotland',
    description: 'Drop-in to a series of talks and demonstrations on maintaining and adapting your historic building to meet the challenges of climate change. <br/> You’ll have the chance to meet and ask questions to experts from <br/> Historic Environment Scotland, the Sustainable Traditional Buildings Alliance, and Home Energy Scotland.',
    link: 'https://www.eventbrite.co.uk/e/historic-environment-scotland-retrofit-at-space-public-drop-in-tickets-200784952197'
  },
  {
    title: 'Holyrood Lodge Retrofit Insight Tour - at Holyrood Lodge', start: new Date('November 11, 2021 10:30:00'), end: new Date('November 11, 2021 15:45:00'), organiser: 'Historic Environment Scotland',
    description: 'At Holyrood Park Lodge, you will receive a 45 minute tour by one of HES’s Technical Research Team members to showcase the energy retrofit work that was recently carried out. <br/> This work enabled the Lodge to reach an Energy Performance Certificate at Band C from a previous Band F. <br/> The tour will include a detailed explanation of the upgrade works and through the various viewing panels that have been created at the Lodge, <br/> visitors will be able to see the installed insulation materials in situ. ',
    link: 'https://www.eventbrite.co.uk/e/holyrood-lodge-retrofit-insight-tour-tickets-198164574577'
  },
  {
    title: 'HES Retrofit at SpACE Professional Day', start: new Date('November 12, 2021 10:30:00'), end: new Date('November 12, 2021 16:45:00'), organiser: 'Historic Environment Scotland',
    description: 'A series of talks and lectures will provide an overview of the latest research, <br/> trials and guidance that have been developed and carried out by Historic Environment Scotland and <br/> partners to improve energy efficiency and sustainability of our traditional and historic environment in Scotland.',
    link: 'https://www.eventbrite.co.uk/e/historic-environment-scotland-retrofit-at-space-tickets-194651597167'
  },
  {
    title: 'Holyrood Lodge Retrofit Insight Tour - at Holyrood Lodge', start: new Date('November 12, 2021 10:30:00'), end: new Date('November 12, 2021 15:45:00'), organiser: 'Historic Environment Scotland',
    description: 'At Holyrood Park Lodge, you will receive a 45 minute tour by one of HES’s Technical Research Team members to showcase the energy retrofit work that was recently carried out. <br/> This work enabled the Lodge to reach an Energy Performance Certificate at Band C from a previous Band F. <br/> The tour will include a detailed explanation of the upgrade works and through the various viewing panels that have been created at the Lodge, <br/> visitors will be able to see the installed insulation materials in situ. ',
    link: 'https://www.eventbrite.co.uk/e/holyrood-lodge-retrofit-insight-tour-tickets-198164574577'
  },
  {
    title: 'BEFS Tenement maintenance = sustainable homes', start: new Date('November 12, 2021 18:00:00'), end: new Date('November 12, 2021 19:30:00'), organiser: 'Built Environment Forum Scotland',
    description: 'BEFS, Under One Roof and Novoville present an event about why tenement maintenance is important for sustainable homes. <br/> Come along to hear from our speakers about how to organise repairs, share information with your neighbours, <br/> explore how to form owners associations and find out what support is out there to help you get started <br/> – as well as looking at how maintaining your home plays an important role in tackling climate change.',
    link: 'https://www.eventbrite.co.uk/e/tenement-maintenance-sustainable-homes-tickets-198905570917?aff=Bulletin'
  },
  {
    title: 'RIAS COP - reCAP: Echoes from the Summit', start: new Date('November 15, 2021 18:00:00'), end: new Date('November 15, 2021 20:00:00'), organiser: 'Royal Incorporation of Architects in Scotland',
    description: 'Join the RIAS and Built Environment Professionals at an informal round table discussion. <br/> Hear first-hand reflections from those who attended or participated in the summit and learn about their personal agendas.', 
    link: 'https://www.eventbrite.co.uk/e/204963680887'
  },
  {
    title: 'Delivering conservation and energy efficiency on the Canongate', start: new Date('November 16, 2021 18:30:00'), end: new Date('November 16, 2021 19:30:00'), organiser: 'Edinburgh World Heritage Trust',
    description: 'Join us to hear about the Canongate Housing Energy Efficiency and <br/> Conservation project with Yann Grandgirard, EWH Climate Change Manager',
    link: 'https://www.eventbrite.co.uk/e/delivering-conservation-and-energy-efficiency-on-the-canongate-tickets-203177107197'
  },
  {
    title: 'City of Edinburgh Council – Housing Sustainability Approach', start: new Date('November 17, 2021 18:30:00'), end: new Date('November 17, 2021 21:00:00'), organiser: 'SpACE',
    description: 'All about Edinburgh. Examples of retrofit work carried out, new build housing planned, and the Council’s plans for the future.',
    link: 'https://www.eventbrite.co.uk/e/edinburgh-housing-retrofit-new-build-and-future-strategy-tickets-200524784027'
  },
  {
    title: 'Local Leaders: Design a New Space for Climate Action together', start: new Date('November 18, 2021 18:30:00'), end: new Date('November 18, 2021 20:30:00'), organiser: 'Our Future Edinburgh',
    description: 'A workshop where we can explore, together, <br/> what is needed to create a new space in Edinburgh where we can all take part in climate and biodiversity actions together.',
    link: 'https://www.eventbrite.co.uk/e/communities-design-a-new-space-for-climate-action-in-edinburgh-tickets-203997240237'
  },
  {
    title: 'Rab and Denise Bennetts lecture to EUSAS', start: new Date('November 19, 2021 18:00:00'), end: new Date('November 19, 2021 20:00:00'), organiser: 'SpACE',
    description: 'At the invitation of Edinburgh University Student Architects Society (EUSAS) <br/> Rab and Denise Bennetts’ talk will focus on urban buildings and low carbon architecture, <br/> describing formative influences and projects over thirty years.',
    link: 'https://www.eventbrite.co.uk/e/rab-and-denise-bennetts-lecture-to-eusas-tickets-211698845947'
  },
  {
    title: 'Desperate Times call for Desperate Measures - Historic Buildings and the Climate Emergency', start: new Date('November 22, 2021 18:30:00'), end: new Date('November 22, 2021 20:00:00'), organiser: 'Architectural Heritage Society of Scotland',
    description: 'AHSS is concerned that the Scottish Government’s ‘Heat in Buildings’ policy should not have unforeseen detrimental consequences for the character and <br/> historic importance of Scotland’s Listed Buildings and Conservation Areas and for traditionally constructed buildings. <br/> The AHSS contribution to this debate will use the tried format of a panel, carefully orchestrated by a Chair, responding to questions from the audience, both present in the room and online. <br/> There will be a number of questions planted in the audience, of which the panel would have prior knowledge, and these would lead on to other unscripted contributions. <br/> It will be challenging, interesting and possibly contentious.',
    link: 'https://www.eventbrite.co.uk/e/question-time-scotlands-historic-buildings-and-the-climate-emergency-tickets-202523913477'
  },
  {
    title: 'Heritage Under Threat: Climate Change in the Old & New Towns of Edinburgh', start: new Date('November 23, 2021 18:30:00'), end: new Date('November 23, 2021 19:30:00'), organiser: 'Edinburgh World Heritage Trust',
    description: 'Join us to hear about the results of EWH’s recent project aiming to understand the impact of climate change on Edinburgh’s World Heritage Site.',
    link: 'https://www.eventbrite.co.uk/e/heritage-under-threat-climate-change-in-the-old-new-towns-of-edinburgh-tickets-207340309447'
  },
  {
    title: 'ESALA Climate Action Pecha Kucha PERSON', start: new Date('November 24, 2021 14:00:00'), end: new Date('November 24, 2021 16:00:00'), organiser: 'ESALA',
    description: 'You are invited to join us for a Pecha-Kucha style presentation, in person, <br/> of twelve ESALA staff and students projects which respond in multi-faceted ways to our climate emergency context',
    link: 'https://www.eventbrite.co.uk/e/214547616697'
  },
  {
    title: 'ESALA Climate Action Pecha Kucha VIRTUALLY', start: new Date('November 24, 2021 14:00:00'), end: new Date('November 24, 2021 16:00:00'), organiser: 'ESALA',
    description: 'You are invited to join us for a Pecha-Kucha style presentation, online, <br/> of twelve ESALA staff and students projects which respond in multi-faceted ways to our climate emergency context',
    link: 'https://www.eventbrite.co.uk/e/214584517067'
  },
  {
    title: 'Post COP26 - utilising digital technology to help cities drive real positive impact', start: new Date('November 25, 2021 18:15:00'), end: new Date('November 25, 2021 20:30:00'), organiser: 'University of Edinburgh Design Informatics, VuCity, ARUP, Grand Bequest, Commonground',
    description: 'Following the historical COP26 event, we are all left with the responsibility to continue the momentum and begin driving real, <br/> positive change in our local communities… but just how are we going to do that? <br/> This discussion highlights innovative digital technology that is being developed and deployed at the local level <br/> to help cities and local citizens harness new technology to create the plans we need to reach our net-zero goals.',
    link: 'https://www.eventbrite.co.uk/e/post-cop26-using-digital-technology-to-help-cities-drive-positive-impact-tickets-206700776587'
  },
  {
    title: 'Construction Skills', start: new Date('November 29, 2021 09:30:00'), end: new Date('November 29, 2021 11:30:00'), organiser: 'Napier University, Balfour Beatty, Sir Robert McAlpine',
    description: 'Unlocking Net Zero Future Construction Skills and Careers <br/> Uncover the opportunities and pathways into sustainable careers in construction and the built environment <br/> Balfour Beatty, Sir Robert McAlpine and the Housing, Construction & Infrastructure Skills Gateway',
    link: 'https://www.eventbrite.co.uk/e/unlocking-net-zero-future-construction-skills-and-careers-tickets-216443577567'
  },
  {
    title: 'Good Housing For People & Planet - Refurbish & Retrofit', start: new Date('November 29, 2021 18:30:00'), end: new Date('November 29, 2021 20:30:00'), organiser: 'Architecture Fringe',
    description: 'A provocative evening of inspiration exploring built examples of refurbishment and retrofit in Scotland and further afield, <br/> and examining how architects can be more upfront in communicating the values of retaining buildings within our towns and cities.',
    link: 'https://www.eventbrite.co.uk/e/good-housing-for-people-planet-refurbish-retrofit-tickets-207523366977'
  },
  {
    title: 'Edinburgh Climate Strategy', start: new Date('November 30, 2021 18:30:00'), end: new Date('November 30, 2021 19:30:00'), organiser: 'SpACE',
    description: 'Leader of The City of Edinburgh Council, Adam McVey will speak about the 2030 Climate Strategy for Edinburgh, <br/> which sets out a vision for how Scotland’s capital will become a net zero, climate ready city by 2030.',
    link: 'https://www.eventbrite.co.uk/e/edinburgh-2030-climate-strategy-tickets-219239931537'
  },
  {
    title: 'What if ……we designed our places with people?', start: new Date('December 1, 2021 13:30:00'), end: new Date('December 1, 2021 14:30:00'), organiser: 'Architecture and Design Scotland',
    description: 'Hosted by Architecture and Design Scotland, <br/> Scotland’s design agency, this talk will reflect on how we can work together to create a Scotland whose places are healthy, sustainable and thriving.',
    link: 'https://www.eventbrite.co.uk/e/talk-what-if-we-designed-our-places-with-people-tickets-217566325737'
  },
  {
    title: 'Delivering Net Zero Through Passivhaus', start: new Date('December 1, 2021 18:00:00'), end: new Date('December 1, 2021 19:30:00'), organiser: 'Architype and the UK Passivhaus Trust',
    description: 'Scotland’s ambitious zero carbon targets represents a massive challenge, <br/> this event explores the key role that Passivhaus and EnerPHit are playing to meet these targets.',
    link: 'https://www.eventbrite.co.uk/e/delivering-net-zero-through-passivhaus-tickets-205032767527'
  },
  {
    title: 'SEDA Showcase: ‘Show and Tell’ social', start: new Date('December 3, 2021 18:00:00'), end: new Date('December 3, 2021 21:00:00'), organiser: 'SEDA',
    description: 'SEDA’s annual social night showing what the eco-designers of Scotland have been working on and thinking about in the last year, <br/> with drinks and snacks. Open to all.',
    link: 'https://sedashowandtell2021.eventbrite.co.uk'
  },
  {
    title: 'Pecha Kucha EDI Vol-43: COP(yer whack)26…what’s next', start: new Date('December 4, 2021 18:00:00'), end: new Date('December 4, 2021 20:00:00'), organiser: 'Gordon Duffy, curator Pecha Kucha EDI',
    description: 'Presenters will talk about the issues presented by the Climate Emergency and the built environment',
    link: 'https://www.eventbrite.com/e/pecha-kucha-edi-vol-43-copyer-whackwhats-next-tickets-218211515517'
  },
]

const Events = () => {
  return (
    <StaticQuery
      query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
        <Section id="events" accent>
            <StyledContainer style={{ position: 'relative'}}>
              <GridAuthors>
                <h1>Events</h1>
                  <p>
                    Throughout the five weeks SpACE is open, a range of public and private events added to the content of the exhibition through workshops, debates, seminars and networking.  Community organisations, designers, contractors, planners, advisory bodies, heritage groups and schools were among those who used SpACE to increase their understanding of the built environment and the importance of thorough analysis, good design and construction.
                    All events were free to attend and all but two were open to the public, bookable through Eventbrite. The venue accommodated up to 80 people seated and some events were also linked to Zoom.
                  <br/> 
                  </p>  
                  <p>
                  Edinburgh Chamber of Commerce has also been running a parallel series of events on behalf of City of Edinburgh Council during COP26.  Its website is <ExternalLink href="https://www.netzeroedinburgh.org"> www.netzeroedinburgh.org </ExternalLink>
                  </p> 
                  <p> 
                    To see the scope of exhibitions that were held at the pop-up SpACE, see the list below.
                  </p>
                  <Calendar events={EVENTS}/>
              </GridAuthors>    
            </StyledContainer>
        </Section>
        )}
    />
    
  )
}

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  position: relative;
  color: ${props => props.theme.color.white.regular};
  

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;
const GridAuthors = styled.div`
  display: grid;
  grid-template-columns: 3fr;
  grid-gap: 20px;
  align-items: top;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 3fr;
  `}
  h1 {
    margin-bottom: 16px;
    text-align: center;
  }
  h2 {
    margin-bottom: 16px;
    color: white;
  }
  p {
    margin-bottom: 16px;
    color: white;
    text-align: left;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 2fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  width: 800px;
  margin: -80px 0;
  position: absolute;
  top: 0;
  left: 70%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 20%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

export default Events;

