import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Section, Container } from '@components/global';
import LinkedInIcon from '@static/icons/linkedin.svg';
import InstagramIcon from '@static/icons/instagram.svg';
import TwitterIcon from '@static/icons/twitter.svg';
import ExternalLink from '@common/ExternalLink';
import Img from 'gatsby-image';

const SOCIAL = [
  {
    icon: LinkedInIcon,
    link: 'https://www.linkedin.com/company/space-edinburgh/about/?viewAsMember=true',
  },
  {
    icon: InstagramIcon,
    link: 'https://www.instagram.com/Space_edinburgh',
  },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/space_edinburgh',
  },
];

const About = () => (
  <StaticQuery
  query={graphql`
  query {
    art_fast: file(
      sourceInstanceName: { eq: "art" }
      name: { eq: "letsGrow" }
    ) {
      childImageSharp {
        fluid(maxWidth: 760) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    art_learn: file(
      sourceInstanceName: { eq: "art" }
      name: { eq: "greenIdeas" }
    ) {
      childImageSharp {
        fluid(maxWidth: 760) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`}
    render={data => (
      <Section id="our mission">
        <Center><h1>Our Mission</h1><br/></Center>
        
        <Container>
          <Grid>
            <div>
              <h2>What</h2>
              <p>
              SpACE is the Space for Architecture, Carbon and Environment.  Launched in 2021, it started with a pop-up public exhibition and events venue in Edinburgh that addressed the biggest issue of our time - the climate emergency - and how the built environment affects it. SpACE is for everyone involved with buildings and places, from the general public to professionals, planners, developers, contractors, heritage groups, students and many others. The pop-up SpACE is now closed but further events are anticipated in 2022. 
              </p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <LightBulb>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </LightBulb>
            <div>
              <h2>When</h2>
              <p>
              The opening of SpACE in Edinburgh was on 1st November 2021 to coincide with the huge interest being generated by COP26 in Glasgow. SpACE carried on for three weeks after COP26 was over until 4th December.  The pop-up exhibition and events venue was a testbed for the future and studies are underway to see where and when it can reappear in future.
              <br/>
              </p>
            </div>
          </Grid>
          <Grid>
            <Art>
              <h2>Where</h2>
              <p>
              The pop-up SpACE was located at the old Fire Station on Lauriston Place, next to Edinburgh College of Art, Edinburgh EH3 9DF. Until a new venue is found, the website will be the vehicle for updates.
              </p> 
            </Art>
            <div>
      
              <br/>
              <h2>Who</h2>
              <p>
              SpACE is a Scottish Registered Charity run by volunteers and funded by a broad group of public and private sector organisations and individuals. A full list of credits is shown below.
              </p>
            </div>
          </Grid>
          <StyledContainer>
          <SocialIcons>
              {SOCIAL.map(({ icon, link }) => (
                <ExternalLink key={link} href={link}>
                  <img src={icon} alt="link" />
                </ExternalLink>
              ))}
            </SocialIcons>
            </StyledContainer>
        </Container>
      </Section>
    )}
  />
);

const StyledContainer = styled(Container)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;


const SocialIcons = styled.div`
  color: ${props => props.theme.color.white.regular};
  display: none;

  img {
    margin: 0 8px;
    width: 35px;
    height: 35px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    display: flex;
  }
`;

const Center = styled(Container)`
  display: flex;
  justify-content: center;
  justify-items: center;

  div {
    justify-content: center;
    justify-items: center;
  }
  img {
    justify-content: center;
    justify-items: center;
  }
  h1 {
    margin-bottom: 16px;
    text-align: center;
  }
  p {
    margin-bottom: 16px;
    text-align: center;
  }
  @media {
    justify-content: center;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}
  h1 {
    margin-bottom: 16px;
    text-align: center;
  }
  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
        ${LightBulb} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

const LightBulb = styled.figure`
  margin: 0;
  max-width: 200px;
  width: 100%;
`;

export default About;
