import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import { Section, Container } from '@components/global';

const Team = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_team: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "team_work" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="exhibition" accent>
        <StyledContainer style={{ position: 'relative' }}>
          <GridAuthors>
            <h1 text-align="center">The Exhibition</h1>
            <p>
              SpACE was open to the public 11am – 6pm every day, from 1st November to 4th December 2021.
              <br /><br />
              The exhibition is still available on this website and is in two major parts.  The first section describes the changing climate and the need to greatly reduce our carbon-dioxide emissions from buildings, construction and some aspects of our lifestyles.  The second part looks in detail at case-studies of typical Edinburgh buildings, so that visitors can see how their homes, offices and public buildings can be improved to provide a cleaner, healthier, low carbon future. The emphasis is on existing buildings, as these represent the vast majority of carbon emissions, but the case studies also examine new buildings that cater for Edinburgh’s expansion.
              <br /><br />
              Links to additional information will be added as it becomes available.  
              <br/><br />
              {'To view the exhibition separately click '}
              <a href="https://space-edinburgh.virtual-engage.com/" target="_blank" rel="noopener noreferrer">
                {'here'}
              </a>
            </p>
            <iframe
              title="Virtual engage room"
              width="100%"
              height="500"
              src="https://space-edinburgh.virtual-engage.com/">
            </iframe>
            <p>{"We want your feedback about the 'Virtual Engage' and Events, "}
            <a href="https://ovearup.onlinesurveys.ac.uk/space-virtual-engage-feedback-" target="_blank" rel="noopener noreferrer"> here </a>
            </p>
          </GridAuthors>
        </StyledContainer>
      </Section>
    )}
  />
);


const Art = styled.figure`
  width: 800px;
  margin: -80px 0;
  position: absolute;
  top: 0;
  left: 70%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 20%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  position: relative;
  color: ${props => props.theme.color.white.regular};
  

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;
const GridAuthors = styled.div`
  display: grid;
  grid-template-columns: 3fr;
  grid-gap: 20px;
  align-items: top;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    grid-template-columns: 3fr;
  `}
  h1 {
    margin-bottom: 16px;
    text-align: center;
  }
  h2 {
    margin-bottom: 16px;
    color: white;
  }
  p {
    margin-bottom: 16px;
    color: white;
    text-align: left;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 2fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
    props.inverse &&
    `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

export default Team;
