import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Section, Container } from '@components/global';
import mainLogo from '@images/logos/sponsors-image.png';

const UsedBy = () => (
  <StaticQuery
    query={graphql`
      query {
        art_story: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "tell_story_white" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="sponsors">
        <Center>
        <Container>        
            
              <div>
              <h1>Sponsors</h1>
              <p><img  src={mainLogo} alt="sponsors" width="60%"/></p>
              {/* <LogoGrid>
                {LOGOS.map(({ logo, link }) => (
                  <ExternalLink key={link} href={link}>
                    {logo()}
                  </ExternalLink>
                ))}
              </LogoGrid> */}
              </div>
              {/* <Art>
                <Img fluid={data.art_story.childImageSharp.fluid} />
              </Art> */}
            
        </Container>
        </Center>
      </Section>
    )}
  />
);

const Center = styled(Container)`
  display: flex;
  justify-content: center;
  justify-items: center;

  div {
    justify-content: center;
    justify-items: center;
  }
  img {
    justify-content: center;
    justify-items: center;
  }
  h1 {
    margin-bottom: 16px;
    text-align: center;
  }
  p {
    margin-bottom: 16px;
    text-align: center;
  }
  @media {
    justify-content: center;
  }
`;

export default UsedBy;
