import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"; // needed
import listPlugin from '@fullcalendar/list'; //For List View
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';


const Calendar = (events) => {
    function renderEventContent(eventInfo) {
      return (
        <div style={{'width': '100%', 'overflow': 'hidden'}} data-tip={tooltip(eventInfo)} 
        onClick={ eventInfo.event.extendedProps.link ? () => window.open(eventInfo.event.extendedProps.link, "_blank") : undefined }>
          <i>{eventInfo.event.title} {eventInfo.event.extendedProps.link && <span> (More detail and book here) </span>} </i> 
          <ReactTooltip multiline={true} textColor='white' backgroundColor='black' width='300px'/>
        </div>
        )
    }
  
    function tooltip(eventInfo) {
      let string = `${eventInfo.event.title} <br/>`
      if(eventInfo.event.start.toDateString() === eventInfo.event.end.toDateString()) {
        string += `${eventInfo.event.start.toDateString()}<br />`
      } else {
        string += `${eventInfo.event.start.toDateString()} to the ${eventInfo.event.end.toDateString()}<br />`
      }
      if(eventInfo.event.extendedProps.organiser) {
        string += `Organised by ${eventInfo.event.extendedProps.organiser}<br/>`
      }
      if(eventInfo.event.extendedProps.description) {
        string += `${eventInfo.event.extendedProps.description}`
      }
      return string
    }

    return(
      <StyledContainer>
      <FullCalendar
      height={700}
      plugins={[ dayGridPlugin, interactionPlugin, listPlugin ]}
      initialDate="2021-11-01"
      initialView="listYear"
      eventContent={renderEventContent}
      events={events}
      />
      </StyledContainer>
    )
  
}

const StyledContainer = styled.div`
color: ${props => props.theme.color.black.regular};
`



export default Calendar;

