import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Container } from '@components/global';
import { ReactComponent as SpaceWhite } from '@images/logos/spaceWhite.svg';
import LinkedInIcon from '@static/icons/linkedin-white.svg';
import InstagramIcon from '@static/icons/instagram-white.svg';
import TwitterIcon from '@static/icons/twitter-white.svg';
import ExternalLink from '@common/ExternalLink';


const SOCIAL = [
  {
    icon: LinkedInIcon,
    link: 'https://www.linkedin.com/company/space-edinburgh/about/?viewAsMember=true',
  },
  {
    icon: InstagramIcon,
    link: 'https://www.instagram.com/Space_edinburgh',
  },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/space_edinburgh',
  },
];


const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        art_build: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "build" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Container>
          <Grid>
          <SocialIcons>
              {SOCIAL.map(({ icon, link }) => (
                <ExternalLink key={link} href={link}>
                  <img src={icon} alt="link" />
                </ExternalLink>
              ))}
            </SocialIcons>
            <Art>
              <p><SpaceWhite /></p>
            </Art>
          </Grid>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const SocialIcons = styled.div`
  color: ${props => props.theme.color.white.regular};
  display: flex;

  img {
    margin: 0 8px;
    width: 35px;
    height: 35px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  padding-top: 128px;
  padding-bottom: 40px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding-top: 100px;
  }
`;

const Art = styled.figure`
  width: 30vw;
  margin: 0;
  margin-right: 0;

  p {
    align: right;
    text-align: right;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 50vw;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 60vw;
  }

`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
  grid-gap: 64px;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-items: center;
    grid-template-columns: 1fr;
    grid-gap: 80px;

    // > ${Art} {
    //   order: 2;
    // }
  }
`;

export default Header;
