import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Section, Container } from '@components/global';

const Credits = () => (
  <StaticQuery
  query={graphql`
  query {
    art_fast: file(
      sourceInstanceName: { eq: "art" }
      name: { eq: "recycle" }
    ) {
      childImageSharp {
        fluid(maxWidth: 760) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`}
    render={data => (
      <Section id="credits">
        <Container style={{ position: 'relative' }}>
          
          
          <GridAuthors inverse>
          <h1>Credits</h1>
            <div>
              <h2>Contributors and authors</h2>
              <p>
              Ann-Marie Fallon 		(Architype)<br/>
              Steven Brown 			(Arup) <br/>
              Graeme Gidney			(Arup)<br/>
              Ben Hopkins 			(Bennetts Associates)<br/>
              Rab Bennetts			(Bennetts Associates)<br/>
              Chris Murray 			(CCG Group) <br/>
              Elizabeth McCarroll 		(City of Edinburgh Council) <br/>
              Graham Connor 		(Cooper Cromar)<br/>
              Fiona Rankin 			(Edinburgh World Heritage Trust)<br/>
              Yann  Grandgirard		(Edinburgh World Heritage Trust) <br/>
              Jamie Brogan			(Edinburgh Climate Change Institute)<br/>
              Joanne McClelland		(GLM)<br/>
              Aythan Lewis			(GLM)<br/>
              Roger Curtis 			(Historic Environment Scotland)<br/>
              Neil Brady-Campbell		(Michael Laird Architects)<br/>
              Chris Milan			(Parabola)<br/>
              Ewan Anderson			(7N Architects)<br/>

              </p>
            </div>
          </GridAuthors>
          <GridAuthors inverse>
            <div>
              <h2>Funders and ‘in-kind’ donors</h2>
              <p>
              Architecture & Design Scotland<br/>
              Arup<br/>
              Bennetts Associates, Architects<br/>
              Creative Scotland (Open Lottery Fund)<br/>
              City of Edinburgh Council, COP26 programme<br/>
              Cundall, Engineers<br/>
              Edinburgh Architectural Association<br/> 
              Fiona Rankin<br/>
              GLM, Surveyors, Architects and Project Managers<br/>
              Historic Environment Scotland<br/>
              Lendlease<br/>
              Oberlanders, Architects<br/>
              Rab and Denise Bennetts <br/>
              Reiach & Hall, Architects<br/>
              Royal Institute of Chartered Surveyors<br/>
              Royal Incorporation of Architects in Scotland <br/>
              Scottish Government <br/>
              Sir Robert McAlpine<br/>
              University of Edinburgh <br/>
              7N Architects<br/>
              </p>
            </div>
          </GridAuthors>
          <GridAuthors inverse>
            <div>
              <h2>Design and implementation </h2>
              <p>
              Rab Bennetts (Curation) 	<br/>			
              7N Architects (Exhibition Design)	<br/>	
              Submarine (Graphic Design) <br/>		
              Eva Coutts (Copywriter)			<br/>
              TB&amp;A (Services and lighting) 	<br/>	
              Sir Robert McAlpine (Builder’s work)	<br/>		
              Lendlease (Project management support)	<br/>
              University of Edinburgh (Property and support staff)	<br/>
              University of Edinburgh (Furniture and equipment) 	<br/>
              Arup (Digital Design) 	<br/>
              </p>
            </div>
          </GridAuthors>
          <GridAuthors inverse>
            <div>
              <h2>SpACE Steering Group</h2>
              <p>
              Dr Richard Anderson 		(University of Edinburgh)	<br/>
              Rab Bennetts* (chair) 		(Bennetts Associates) <br/>
              Denise Bennetts 		(Bennetts Associates)<br/>
              Cllr Neil Gardiner		(City of Edinburgh Council) <br/>
              Graeme Gidney 		(Arup)<br/>
              Eamonn Hughes* 		(Sir Robert McAlpine)<br/>
              Aythan Lewes			(RICS/GLM)	<br/>
              Joanne McClelland		(Edinburgh Architectural Association/GLM) <br/>
              Jim McDonald 			(Architecture & Design Scotland)<br/>
              Fiona Rankin*			(Edinburgh World Heritage Trust)<br/>
              Tamsie Thomson 		(Royal Incorporation of Architects in Scotland) <br/>
              Robin Webster* 		(Royal Incorporation of Architects in Scotland)<br/>
              <br/>
              * Trustee of SpACE

              </p>
            </div>
          </GridAuthors>
        </Container>
      </Section>
    )}
  />
);

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

const GridAuthors = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 20px;
  text-align: center;
  align-items: top;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: center;
    grid-template-columns: 3fr;
  `}
  h1 {
    margin-bottom: 16px;
    text-align: center;
  }
  h2 {
    margin-bottom: 16px;
  }
  h3 {
    color: #000; /* Fallback for older browsers */
    color: rgba(0, 0, 0, 0.0);
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 2fr;
    text-align: center;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

export default Credits;
